/* You can add global styles to this file, and also import other style files */
@import 'theme/theme-variables';
@import 'theme/fonts';
@import '~@pik-ui/ng-components/style-fm.css';
@import '~@pik-ui/quill/quill.core.css';
@import '~ng-zorro-antd/src/ng-zorro-antd.min.css';

* {
  box-sizing: border-box;
  outline: none;
}

a {
  cursor: pointer;
  color: $fm-aqua;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    color: $fm-aqua;
  }
}

.cdk-visually-hidden {
  display: none;
}

table {
  width: 100%;
}

html,
body {
  min-width: 1340px;
  height: 100%;
  margin: 0;
  font-family: 'GraphikLCG', 'Roboto', sans-serif;
}

body {
  padding-left: 50px;
  background: $fm-gray5;
}

button {
  font-family: 'GraphikLCG', 'Roboto', sans-serif;
}

perfect-scrollbar.fm-family .ps,
perfect-scrollbar.fm-families .ps {
  height: 100% !important;
}

.fmElementTable {
  border-collapse: collapse;
  font-weight: 500;
  font-size: 14px;

  tr {
    th {
      font-weight: 500;
      font-size: 11px;
      text-transform: uppercase;
      color: $main-blue;
      background: $fm-gray5;
      padding: 16px;
      text-align: left;
    }

    td {
      border-bottom: 1px solid $fm-gray4;
      padding: 15px 10px;
      color: $fm-gray1;
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  .clicked {
    color: $main-blue;
    cursor: pointer;

    &:hover {
      color: $main-orange;
      text-decoration: underline;
    }
  }
}

.drawer {
  width: 100%;
  max-width: 738px;

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
  }
}

.family-relation-drawer {
  width: 100%;

  .pik-tabs-header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;

    li {
      padding-top: 0;
    }
  }
}

.pik-tabs-header li,
.pik-modal-title,
.pik-paginator ul li,
.pik-table-footer__total,
.pik-table-footer__size>span,
.pik-select-trigger,
.pik-tag,
.pik-button-content,
.pik-switch-label>span,
.pik-input,
.pik-option,
.pik-checkbox-label>span,
.pik-radio-button-label>span,
.pik-form fieldset legend,
h2,
.pik-card-title,
.pik-menu-item,
.datepicker__trigger span {
  font-family: 'GraphikLCG', sans-serif !important;
  font-weight: 500 !important;
}

.pik-button--inverse .pik-button-icon path {
  stroke: rgb(0, 151, 255);
}

.pik-select-trigger .pik-select-arrow path,
.pik-select-arrow path {
  stroke: #bdbdbd;
}

// todo: выпилить и переделать на pik-empty (его подкрутить нужно для вставки любой иконки)
.empty-message {
  display: flex;
  flex-direction: column;
  padding: 48px;
  align-items: center;
  justify-content: center;
  color: $fm-gray3;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  svg-icon {
    margin-bottom: 8px;
  }
}

.versionSelect {
  .pik-button-content {
    width: 95%;
  }

  app-family-version-option {
    display: block;
    width: 100%;
  }
}

.page-main-loader {
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 2;
  }

  &.loading {
    visibility: visible;

    &:before {
      opacity: 0.5;
      visibility: visible;
    }
  }

  pik-loader {
    position: relative;
    z-index: 3;
  }
}

.pik-sort-header {
  fm-info-tooltip {
    svg {
      transform: none !important;

      &:hover {
        path {
          stroke: $main-blue !important;
        }
      }

      path {
        stroke: rgb(224, 224, 224) !important;
      }
    }
  }
}

.pik-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) rotate(180deg) !important;
}

.table-settings-drawer {
  width: 100%;
  padding: 16px 0 32px;

  .pik-drawer-header .pik-tabs-content {
    display: none;
  }

  .pik-form {
    fieldset {
      margin-bottom: 24px;
    }

    legend {
      margin-bottom: 12px;
    }
  }

  .pik-radio-group .pik-radio-button:not(:last-child) {
    margin-right: 16px;
  }
}

.pik-error {
  margin-top: 4px;
}

.pik-drawer-scroll>div {
  height: 100% !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.2 !important;
}

.fm-info-tooltip {
  max-width: 482px !important;
}

.pik-select-dropdown {
  flex-direction: column;
}

.pik-button-icon {
  svg {
    display: block;
  }
}

.clickable {
  padding-top: 6px;
}

.search-toolbar {
  display: flex;
  width: 100%;

  pik-search-input {
    flex: 1;
    margin-right: 8px;

    ::ng-deep .pik-input {
      padding: 6px 11px 4px;
      min-height: 36px;
    }
  }
}

.drawer-full-width {
  width: 100%;
}

.pik-tooltip {
  white-space: pre-line;
  overflow: hidden;
}

.pik-dropdown-content .pik-menu,
.pik-dropdown-content .pik-menu .pik-menu-item {
  margin: 0;
}

.sidenav-backdrop {
  width: 250px;
}

.table-link {
  &:hover {
    text-decoration: underline;
  }
}

.draggable-settings-checkbox {
  position: relative !important;
  padding-left: 32px !important;
  display: flex !important;
  align-items: center !important;

  svg-icon {
    opacity: 0.25;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: ns-resize;
    display: flex;
    align-items: center;
  }
}

.capitalize-sentence {
  text-transform: lowercase;
  display: block;

  &::first-letter {
    text-transform: capitalize;
  }
}

svg-icon {
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

.clickable-row {
  tr.cdk-row {
    cursor: pointer;
  }
}

.pik-modal {
  max-width: 50vw;
  max-height: 50vh;
}

td {
  a[ng-reflect-router-link*='users'] {
    color: #0097ff !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

td.cdk-column-id,
td.cdk-column-name {
  a[ng-reflect-router-link*='users'] {
    color: black !important;
  }
}

.copy__toast {
  position: absolute;
  top: 35px;
  right: -2px;
  width: 25px;
  height: 25px;
  margin-left: 14px;
  margin-bottom: -3px;
  cursor: pointer;

  path {
    stroke: black;
  }

  &:active {
    transform: scale(1.1);

    path {
      stroke: rgb(74, 209, 74);
    }
  }
}

.pik-toast-close {
  path {
    stroke: black !important;
  }
}

.flex {
  display: flex;
}

.mr1 {
  margin-right: 10px;
}

.mr05 {
  margin-right: 5px;
}

.mt2 {
  margin-top: 20px;
}

.ml1 {
  margin-left: 10px;
}

.ml05 {
  margin-left: 5px;
}

.mb1 {
  margin-bottom: 10px;
}

.mb05 {
  margin-bottom: 5px;
}

.mr2 {
  margin-right: 20px;
}

.ml2 {
  margin-left: 20px;
}


.valueItems__label {
  width: 100%;
}
